<template>
  <div>
    <Overlay :overlay="overlay" />
    <CreateDashboardParameterDialog :StoreObj="StoreObj" :dialogCreateDashboardParameter="dialogCreateDashboardParameter" @clicked="createDashboardParameterDialogEmit" />
    <DeleteDashboardParameterDialog
      :StoreObj="StoreObj"
      :dialogDeleteUserDashboardParameter="dialogDeleteUserDashboardParameter"
      @clicked="dialogDeleteUserDashboardParameterEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">Master Tags</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-text-field dense outlined class="field_height field_label_size FontSize maxWidthLarge mt-6 mr-2" v-model="search" label="Search" append-icon="mdi-magnify"></v-text-field>
            <v-btn small color="primary" @click="(dialogCreateDashboardParameter = true), (StoreObj.action = 'CREATE')" class="borderRadius"><v-icon small class="mr-1">mdi-plus</v-icon>Create</v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table dense class="elevation-0" :search="search" :headers="MasterTagsHeader" :items="GetAllMasterParametersList">
          <template v-slot:[`item.tag_id`]="{ item }">
            <div class="FontSize">
              {{ item.tag_id }}
            </div>
          </template>
          <template v-slot:[`item.tag_description`]="{ item }">
            <div class="FontSize">
              {{ item.tag_description }}
            </div>
          </template>
          <template v-slot:[`item.tag_type`]="{ item }">
            <div class="FontSize">
              {{ item.tag_type }}
            </div>
          </template>
          <template v-slot:[`item.commissioning_value`]="{ item }">
            <div class="FontSize">
              {{ item.commissioning_value ? item.commissioning_value : "-" }}
            </div>
          </template>
          <template v-slot:[`item.plant_type`]="{ item }">
            <div class="FontSize">
              {{ item.plant_type ? item.plant_type : "-" }}
            </div>
          </template>
          <template v-slot:[`item.status_or_feedback`]="{ item }">
            <div class="FontSize">
              {{ item.plant_type ? item.status_or_feedback : "-" }}
            </div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon :color="'green'" @click="checkItem(item, 'EDIT')"><v-icon small v-text="'mdi-pencil'"></v-icon></v-btn>
              </template>
              <span v-text="`Edit ${item.tag_id}`"></span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon :color="'red'" @click="checkItem(item, 'DELETE')"><v-icon small v-text="'mdi-delete'"></v-icon></v-btn>
              </template>
              <span v-text="`Delete ${item.tag_description}`"></span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllMasterParameters } from "@/mixins/GetAllMasterParameters.js";
import CreateDashboardParameterDialog from "@/components/MasterTags/Dialogs/CreateDashboardParameterDialog.vue";
import DeleteDashboardParameterDialog from "@/components/MasterTags/Dialogs/DeleteDashboardParameterDialog.vue";
export default {
  components: {
    Overlay,
    CreateDashboardParameterDialog,
    DeleteDashboardParameterDialog,
  },
  mixins: [GetAllMasterParameters],
  data: () => ({
    search: "",
    StoreObj: {},
    overlay: false,
    dialogCreateDashboardParameter: false,
    MasterTagsHeader: [
      { text: "Name", value: "tag_id" },
      { text: "Tag Description", value: "tag_description" },
      { text: "Tag Type", value: "tag_type" },
      { text: "Commissioning Value", value: "commissioning_value" },
      { text: "Plant Type", value: "plant_type" },
      { text: "Status/ Feedback", value: "status_or_feedback" },
      { text: "Action", value: "Actions" },
    ],
    dialogDeleteUserDashboardParameter: false,
  }),
  mounted() {
    this.GetAllMasterParametersMethod();
  },
  methods: {
    checkItem(item, Action) {
      this.StoreObj = item;
      this.StoreObj.action = Action;
      switch (Action) {
        case "DELETE":
          this.dialogDeleteUserDashboardParameter = true;
          break;
        case "EDIT":
          this.dialogCreateDashboardParameter = true;
          break;
      }
    },
    dialogDeleteUserDashboardParameterEmit(Toggle) {
      this.dialogDeleteUserDashboardParameter = false;
      if (Toggle == 2) {
        this.GetAllMasterParametersMethod();
      }
    },
    createDashboardParameterDialogEmit(Toggle) {
      this.dialogCreateDashboardParameter = false;
      if (Toggle == 2) {
        this.GetAllMasterParametersMethod();
      }
    },
  },
};
</script>
