import { listMasterParameters } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllMasterParameters = {
  data() {
    return {
      overlay: false,
      GetAllMasterParametersList: [],
    };
  },
  methods: {
    async GetAllMasterParametersMethod() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listMasterParameters, {
            input: {},
          })
        );
        this.GetAllMasterParametersList = JSON.parse(result.data.ListMasterParameters).data.items;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
      }
    },
  },
};
