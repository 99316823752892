<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="900px" v-model="dialogCreateDashboardParameter">
      <v-card>
        <v-toolbar dark color="primary" dense class="elevation-0">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          <div>Create Master Tags</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="createDashboardParameterDialogEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-form ref="form" class="mt-4">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="4" class="pb-0">
                <v-text-field
                  dense
                  outlined
                  label="Tag ID"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_dashboard_paramater.tag_id"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" class="pb-0">
                <v-text-field
                  dense
                  outlined
                  label="Tag Description"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_dashboard_paramater.tag_description"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" class="pb-0">
                <v-select
                  dense
                  outlined
                  label="Tag Type"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_dashboard_paramater.tag_type"
                  :items="inputTypesList"
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" class="py-0" v-if="create_dashboard_paramater.tag_type == 'AI'">
                <v-text-field
                  dense
                  outlined
                  label="Commissioning Value"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_dashboard_paramater.commissioning_value"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" class="pt-0" v-if="create_dashboard_paramater.tag_type == 'DI'">
                <v-select
                  dense
                  outlined
                  label="Plant Type"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  item-text="plant_name"
                  item-value="plant_name"
                  v-model="create_dashboard_paramater.plant_type"
                  :items="GetAllUFROTypesList"
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" class="pt-0" v-if="create_dashboard_paramater.tag_type == 'DI'">
                <v-select
                  dense
                  outlined
                  label="Status Or Feedback"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_dashboard_paramater.status_or_feedback"
                  :items="statusOrFeedbackItems"
                ></v-select>
              </v-col>
              <!-- <v-col cols="12" xs="12" sm="12" md="2" class="font-weight-bold"> Is An Alarm ?</v-col>
              <v-col cols="12" xs="12" sm="12" md="2" class="font-weight-bold">
                <v-checkbox dense class="pa-0 ma-0" v-model="create_dashboard_paramater.is_alarm"></v-checkbox>
              </v-col> -->
              <!-- <v-col
                cols="12"
                xs="12"
                sm="12"
                md="2"
                class="pt-0"
                v-if="create_dashboard_paramater.tag_type == 'AI' && create_dashboard_paramater.is_alarm == true"
              >
                <v-text-field
                  dense
                  outlined
                  label="Start Value"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_dashboard_paramater.start_value"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="2"
                class="pt-0"
                v-if="create_dashboard_paramater.tag_type == 'AI' && create_dashboard_paramater.is_alarm == true"
              >
                <v-text-field
                  dense
                  outlined
                  label="End Value"
                  class="field_height field_label_size FontSize"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_dashboard_paramater.end_value"
                ></v-text-field>
              </v-col> -->
              <!-- <v-col cols="12" xs="12" sm="12" md="3" class="font-weight-bold"> Show On Dashboard ?</v-col>
              <v-col cols="12" xs="12" sm="12" md="1" class="">
                <v-checkbox dense class="ma-0 pa-0" v-model="create_dashboard_paramater.show_on_dashboard"></v-checkbox>
              </v-col>  -->
            </v-row>
          </v-form>
          <v-card
            outlined
            class="pa-2"
            v-if="
              create_dashboard_paramater.tag_type == 'DI' &&
              create_dashboard_paramater.status_or_feedback &&
              create_dashboard_paramater.status_or_feedback != ''
            "
          >
            <div class="font-weight-bold">Tag Configuration</div>
            <v-divider class="mb-2"></v-divider>
            <v-row no-gutters class="pb-3">
              <v-col cols="12" xs="12" sm="12" md="1" class="font-weight-bold"> Color </v-col>
              <v-col cols="12" xs="12" sm="12" md="1" class="font-weight-bold pl-4"> Value </v-col>
              <v-col
                v-if="create_dashboard_paramater.status_or_feedback == 'FEEDBACK'"
                cols="12"
                xs="12"
                sm="12"
                md="2"
                class="font-weight-bold pl-4"
              >
                Conditional
              </v-col>
              <v-col
                v-if="
                  create_dashboard_paramater.status_or_feedback == 'FEEDBACK' &&
                  create_dashboard_paramater.parameter_values.filter((item) => item.is_conditional == true).length != 0
                "
                cols="12"
                xs="12"
                sm="12"
                md="2"
                class="font-weight-bold pl-4"
              >
                Dependent Tag ID
              </v-col>
              <v-col
                v-if="
                  create_dashboard_paramater.status_or_feedback == 'FEEDBACK' &&
                  create_dashboard_paramater.parameter_values.filter((item) => item.is_conditional == true).length != 0
                "
                cols="12"
                xs="12"
                sm="12"
                md="3"
                class="font-weight-bold pl-5"
              >
                Dependent Tag Value
              </v-col>
              <v-col
                v-if="
                  create_dashboard_paramater.status_or_feedback == 'FEEDBACK' &&
                  create_dashboard_paramater.parameter_values.filter((item) => item.is_conditional == true).length != 0
                "
                cols="12"
                xs="12"
                sm="12"
                md="2"
                class="font-weight-bold"
              >
                Dependent Color
              </v-col>
            </v-row>
            <v-row
              no-gutters
              v-for="(item, idx) in StoreObj.action == 'EDIT'
                ? this.StoreObj.parameter_values
                : create_dashboard_paramater.parameter_values"
              :key="idx"
            >
              <v-col cols="12" xs="12" sm="12" md="1">
                <v-icon :color="item.tag_color.toLowerCase()">mdi-circle-slice-8</v-icon>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="1">
                <v-text-field
                  v-model="item.tag_value"
                  dense
                  outlined
                  class="field_height field_label_size fontSize"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="create_dashboard_paramater.status_or_feedback == 'FEEDBACK'"
                cols="12"
                xs="12"
                sm="12"
                md="2"
                align="center"
              >
                <v-checkbox
                  dense
                  class="py-0 my-0 pl-9"
                  @change="getItem(item)"
                  v-model="item.is_conditional"
                ></v-checkbox>
              </v-col>
              <v-col
                v-if="create_dashboard_paramater.status_or_feedback == 'FEEDBACK'"
                cols="12"
                xs="12"
                sm="12"
                md="2"
                align="center"
              >
                <v-text-field
                  v-if="item.is_conditional == true"
                  dense
                  outlined
                  v-model="item.dependent_tag_id"
                  class="field_height field_label_size fontSize"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="create_dashboard_paramater.status_or_feedback == 'FEEDBACK'"
                cols="12"
                xs="12"
                sm="12"
                md="2"
                align="center"
                class="mx-4"
              >
                <v-text-field
                  v-if="item.is_conditional == true"
                  dense
                  outlined
                  v-model="item.dependent_tag_value"
                  class="field_height field_label_size fontSize"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="create_dashboard_paramater.status_or_feedback == 'FEEDBACK'"
                cols="12"
                xs="12"
                sm="12"
                md="2"
                align="center"
                class="mx-4"
              >
                <v-icon v-if="item.is_conditional == true" :color="item.dependent_color.toLowerCase()"
                  >mdi-circle-slice-8</v-icon
                >
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            small
            class="borderRadius"
            outlined
            color="primary"
            @click="createDashboardParameterDialogEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn small class="mr-2 borderRadius" color="primary" :loading="loading" @click="validateFormMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { GetAllPlantTypes } from "@/mixins/GetAllPlantTypes.js";
import { createMasterParameter, updateMasterParameter } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogCreateDashboardParameter: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  mixins: [GetAllPlantTypes],
  data: () => ({
    Toggle: 0,
    Dashboardtag_type: "",
    loading: false,
    DashboardParameter_email_id: "",
    SnackBarComponent: {},
    create_dashboard_paramater: {
      tag_id: "",
      tag_description: "",
      tag_type: "",
      commissioning_value: "",
      plant_type: "",
      summary_input_type: "",
      status_or_feedback: "",
      parameter_values: [],
      is_alarm: false,
      start_value: "",
      end_value: "",
      show_on_dashboard: true,
    },
    parameterTypeItems: [
      { text: "Operational", value: "OPERATIONAL" },
      { text: "Summary", value: "SUMMARY" },
    ],
    summaryParameterTypeItems: ["UF", "RO1", "RO2"],
    statusOrFeedbackItems: [
      { text: "Status", value: "STATUS" },
      { text: "Feedback", value: "FEEDBACK" },
    ],
    storeParameterValues: [
      {
        tag_color: "RED",
        tag_value: "",
        is_conditional: false,
        dependent_tag_id: "",
        dependent_tag_value: "",
        dependent_color: "",
      },
      {
        tag_color: "GREEN",
        tag_value: "",
        is_conditional: false,
        dependent_tag_id: "",
        dependent_tag_value: "",
        dependent_color: "",
      },
    ],
    inputTypesList: ["DI", "DO", "AI", "AO", "RTD", "OTHER"],
    operatorItems: ["Greater Than", "Less Than", "Equal To"],
  }),
  watch: {
    "create_dashboard_paramater.status_or_feedback"(val) {
      if (val && val != "") {
        switch (val) {
          case "STATUS":
            this.create_dashboard_paramater.parameter_values = this.storeParameterValues.filter(
              (item) => item.tag_color != "yellow"
            );
            break;
          case "FEEDBACK":
            this.create_dashboard_paramater.parameter_values = this.storeParameterValues;
            break;
        }
      }
    },
    dialogCreateDashboardParameter(val) {
      if (val == true) {
        this.GetAllPlantTypesMethod();
        if (this.StoreObj.action == "EDIT") {
          this.create_dashboard_paramater.tag_id = this.StoreObj.tag_id;
          this.create_dashboard_paramater.tag_description = this.StoreObj.tag_description;
          this.create_dashboard_paramater.tag_type = this.StoreObj.tag_type;
          this.create_dashboard_paramater.commissioning_value = this.StoreObj.commissioning_value;
          this.create_dashboard_paramater.plant_type = this.StoreObj.plant_type;
          this.create_dashboard_paramater.status_or_feedback = this.StoreObj.status_or_feedback;
          this.create_dashboard_paramater.is_alarm = this.StoreObj.is_alarm;
          this.create_dashboard_paramater.show_on_dashboard = this.StoreObj.show_on_dashboard;
          if (this.StoreObj.tag_type == "AI") {
            this.create_dashboard_paramater.start_value = this.StoreObj.start_value;
            this.create_dashboard_paramater.end_value = this.StoreObj.end_value;
          }
          console.log("Check create_dashboard_paramater", this.create_dashboard_paramater);
          this.$forceUpdate();
        }
      }
    },
  },
  methods: {
    getItem(item) {
      item.dependent_color = "YELLOW";
      this.create_dashboard_paramater.parameter_values.map((obj, idx) => {
        let Object = obj;
        if (idx != this.create_dashboard_paramater.parameter_values.indexOf(item)) {
          Object.is_conditional = false;
          Object.dependent_tag_id = "";
          Object.dependent_tag_value = "";
          Object.dependent_color = "";
        }
        return Object;
      });
    },
    validateFormMethod() {
      if (this.StoreObj.action == "CREATE") {
        if (
          this.create_dashboard_paramater.tag_id == "" ||
          this.create_dashboard_paramater.tag_description == "" ||
          this.create_dashboard_paramater.tag_type == ""
        ) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Invalid Inputs",
          };
        } else if (this.create_dashboard_paramater.tag_type == "DI") {
          if (this.create_dashboard_paramater.plant_type == "") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Plant Type is Required",
            };
          }
          if (this.create_dashboard_paramater.status_or_feedback == "") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Status or Feedback is Required",
            };
          }
          if (this.create_dashboard_paramater.status_or_feedback == "STATUS") {
            if (
              this.create_dashboard_paramater.parameter_values[0].tag_value == "" ||
              this.create_dashboard_paramater.parameter_values[1].tag_value == ""
            ) {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "Value is Mandatory",
              };
            } else if (
              this.create_dashboard_paramater.parameter_values[0].tag_value < 0 ||
              this.create_dashboard_paramater.parameter_values[0].tag_value > 1 ||
              this.create_dashboard_paramater.parameter_values[1].tag_value < 0 ||
              this.create_dashboard_paramater.parameter_values[1].tag_value > 1
            ) {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "Value should be 0 or 1",
              };
            } else if (
              this.create_dashboard_paramater.parameter_values[0].tag_value ==
              this.create_dashboard_paramater.parameter_values[1].tag_value
            ) {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "Value should not be same",
              };
            } else {
              this.createDashboardParameterMethod();
            }
          }
          if (this.create_dashboard_paramater.status_or_feedback == "FEEDBACK") {
            if (
              this.create_dashboard_paramater.parameter_values[0].tag_value == "" ||
              this.create_dashboard_paramater.parameter_values[1].tag_value == ""
            ) {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "Value is Mandatory",
              };
            } else if (
              this.create_dashboard_paramater.parameter_values[0].tag_value < 0 ||
              this.create_dashboard_paramater.parameter_values[0].tag_value > 1 ||
              this.create_dashboard_paramater.parameter_values[1].tag_value < 0 ||
              this.create_dashboard_paramater.parameter_values[1].tag_value > 1
            ) {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "Value should be 0 or 1",
              };
            } else if (
              this.create_dashboard_paramater.parameter_values[0].tag_value ==
              this.create_dashboard_paramater.parameter_values[1].tag_value
            ) {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "Value should not be same",
              };
            } else if (
              this.create_dashboard_paramater.parameter_values[0].is_conditional == false &&
              this.create_dashboard_paramater.parameter_values[1].is_conditional == false
            ) {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "should be select atleast one Conditional",
              };
            } else if (this.create_dashboard_paramater.parameter_values[0].dependent_tag_id == "") {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "Dependent tag Id is Mandatory",
              };
            } else if (this.create_dashboard_paramater.parameter_values[0].dependent_tag_id.includes("-")) {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "Invalid Dependent tag Id",
              };
            } else if (this.create_dashboard_paramater.parameter_values[0].dependent_tag_value == "") {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "Dependent tag value is Mandatory",
              };
            } else if (
              this.create_dashboard_paramater.parameter_values[0].dependent_tag_value < 0 ||
              this.create_dashboard_paramater.parameter_values[0].dependent_tag_value > 1
            ) {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "Dependent tag value should be 0 or 1",
              };
            } else {
              this.createDashboardParameterMethod();
            }
          }
        } else if (this.create_dashboard_paramater.tag_type == "AI") {
          if (this.create_dashboard_paramater.commissioning_value == "") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Commissioning Value is Required",
            };
          } else {
            this.createDashboardParameterMethod();
          }
        } else {
          this.createDashboardParameterMethod();
        }
      } else {
        this.createDashboardParameterMethod();
      }
      // if (this.$refs.form.validate()) {
      //   this.createDashboardParameterMethod();
      // } else {
      //   this.SnackBarComponent = {
      //     SnackbarVmodel: true,
      //     SnackbarColor: "red",
      //     Top: true,
      //     SnackbarText: "Invalid Inputs",
      //   };
      // }
    },
    async createDashboardParameterMethod() {
      console.log("create_dashboard_paramater", this.create_dashboard_paramater);
      let Mutation_name = this.StoreObj.action == "CREATE" ? createMasterParameter : updateMasterParameter;
      let Mutation_result = this.StoreObj.action == "CREATE" ? "CreateMasterParameter" : "UpdateMasterParameter";
      var inputParams = {
        tag_id: this.create_dashboard_paramater.tag_id.toLowerCase(),
        tag_description: this.create_dashboard_paramater.tag_description,
        tag_type: this.create_dashboard_paramater.tag_type,
        commissioning_value:
          this.create_dashboard_paramater.tag_type == "AI"
            ? this.create_dashboard_paramater.commissioning_value
            : undefined,
        plant_type:
          this.create_dashboard_paramater.tag_type == "DI" ? this.create_dashboard_paramater.plant_type : undefined,
        status_or_feedback:
          this.create_dashboard_paramater.tag_type == "DI"
            ? this.create_dashboard_paramater.status_or_feedback
            : undefined,
        is_alarm: this.create_dashboard_paramater.is_alarm ? this.create_dashboard_paramater.is_alarm : false,
        start_value:
          this.create_dashboard_paramater.tag_type == "AI" && this.create_dashboard_paramater.is_alarm == true
            ? this.create_dashboard_paramater.start_value
            : undefined,
        end_value:
          this.create_dashboard_paramater.tag_type == "AI" && this.create_dashboard_paramater.is_alarm == true
            ? this.create_dashboard_paramater.end_value
            : undefined,
        parameter_values:
          this.create_dashboard_paramater.tag_type == "DI"
            ? this.StoreObj.action == "EDIT"
              ? this.StoreObj.parameter_values.map((item) => {
                  let Object = {};
                  for (let key in item) {
                    Object[key] = item[key];
                    if (key == "tag_id" || key == "dependent_tag_id") {
                      Object[key] =
                        key == "dependent_tag_id" ? item[key].toString().toLowerCase() : item[key].toString();
                    }
                  }
                  return Object;
                })
              : this.create_dashboard_paramater.parameter_values.map((item) => {
                  let Object = item;
                  for (let key in Object) {
                    if (Object[key] == "") {
                      delete Object[key];
                    }
                  }
                  return Object;
                })
            : undefined,
        show_on_dashboard: this.create_dashboard_paramater.show_on_dashboard
          ? this.create_dashboard_paramater.show_on_dashboard
          : false,
      };
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(Mutation_name, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data[Mutation_result]);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.createDashboardParameterDialogEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    createDashboardParameterDialogEmit(Toggle) {
      this.storeParameterValues = [
        {
          tag_color: "RED",
          tag_value: "",
          is_conditional: false,
          dependent_tag_id: "",
          dependent_tag_value: "",
          dependent_color: "",
        },
        {
          tag_color: "GREEN",
          tag_value: "",
          is_conditional: false,
          dependent_tag_id: "",
          dependent_tag_value: "",
          dependent_color: "",
        },
      ];
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
